import Vue from 'vue'
import Vuex from 'vuex'
 
// 挂在Vuex
Vue.use(Vuex)
 
// 创建Vuex对象
const store = new Vuex.Store({
    state:{
    },
    mutations:{
    },
    actions: {
    }
})
 
export default store