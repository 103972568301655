<template>
  <div id="app" style="min-width: 1200px;">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isScrolling: false,
      lastScrollPosition: 0,
      showMobile: false
    }
  },
  components: {
  },
  created() {
    window.addEventListener('scroll', this.handleWindowResize);
  },
  watch: {
    '$route': {
      handler(newValue) {
        this.$nextTick(() => {
          if (this.$refs.commonHeader) {
            this.$refs.commonHeader.activeIndex = newValue.path
          }
        })
        if (newValue.path.includes('mobile')) {
          this.showMobile = true
        } else {
          this.showMobile = false
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
        const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
        if (currentScrollPosition > this.lastScrollPosition) {
          this.isScrolling = true
        } else {
          this.isScrolling = false
        }
        this.lastScrollPosition = currentScrollPosition;
      },
  }
}
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
</style>
