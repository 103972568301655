import Vue from 'vue'
import Router from 'vue-router'
// import { device } from '@/utils/public'
Vue.use(Router)

const routes = [{
    path: '*',
    redirect: '/index'
},
{
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
},
{
    path: '/recruit',
    name: 'recruit',
    component: () => import('../views/recruit.vue')
},
{
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
},
{
    path: '/solution/:id?',
    name: 'solution',
    component: () => import('../views/solution.vue')
},
{
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
},
{
    path: '/technology',
    name: 'technology',
    component: () => import('../views/technology.vue')
},
{
    path: '/product/:id?',
    name: 'product',
    component: () => import('../views/product.vue')
},
{
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/detail.vue')
},
{
    path: '/columnManagement',
    name: 'ColumnManagement',
    component: () => import('../views/back/ColumnManagement.vue')
},
{
    path: '/managementLogin',
    name: 'ManagementLogin',
    component: () => import('../views/back/ManagementLogin.vue')
},
{
    path: '/publishMenu',
    name: 'PublishMenu',
    component: () => import('../views/back/PublishMenu.vue')
},
{
    path: '/publishList',
    name: 'PublishList',
    component: () => import('../views/back/PublishList.vue')
},
{
    path: '/mobile',
    name: 'mobile',
    component: () => import('../views/mobile/index.vue')
},
{
    path: '/mobileAbout',
    name: 'mobileAbout',
    component: () => import('../views/mobile/MobileAbout.vue')
},
{
    path: '/mobileNews',
    name: 'mobileNews',
    component: () => import('../views/mobile/MobileNews.vue')
},
{
    path: '/mobilePay',
    name: 'mobilePay',
    component: () => import('../views/mobile/MobilePay.vue')
},
{
    path: '/mobileProduct',
    name: 'mobileProduct',
    component: () => import('../views/mobile/MobileProduct.vue')
},
{
    path: '/mobileRecruit',
    name: 'mobileRecruit',
    component: () => import('../views/mobile/MobileRecruit.vue')
},
{
    path: '/mobileTechnology',
    name: 'mobileTechnology',
    component: () => import('../views/mobile/MobileTechnology.vue')
}
]
const router = new Router({
    routes: routes,
    // mode: 'history',
    mode: 'hash',
    linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token'); // 获取 token，假设存储在 localStorage 中

   // 如果路径中包含 "login" 字段且用户已登录，则保持在当前页面
   if (to.path.includes('managementLogin') && token) {
     next(from.path); // 重定向到来源页面
   } else if((to.path.includes('columnManagement') || to.path.includes('publishMenu') || to.path.includes('publishList')) && !token) {
     next('/managementLogin'); // 继续路由导航
   } else {
     next()
   }
    // const hasRefreshed = sessionStorage.getItem('hasRefreshed');
    
    // if (!hasRefreshed) {
    //     sessionStorage.setItem('hasRefreshed', 'true');

    //     if (to.path.includes('mobile')) {
    //         // 当前路由包含 'mobile' 字符串
    //         if (device().isPhone) {
    //             // 如果是移动设备，保持原路由
    //             next();
    //             sessionStorage.removeItem('hasRefreshed')
    //         } else {
    //             // 如果不是移动设备，跳转到 /index
    //             next('/index');
    //             sessionStorage.removeItem('hasRefreshed')
    //         }
    //     } else {
    //         // 当前路由不包含 'mobile' 字符串
    //         if (device().isPhone) {
    //             // 如果是移动设备，跳转到 /mobile
    //             next('/mobile');
    //             sessionStorage.removeItem('hasRefreshed')
    //         } else {
    //             // 如果不是移动设备，保持原路由
    //             next();
    //             sessionStorage.removeItem('hasRefreshed')
    //         }
    //     }
    // } else {
    //     next();
    //     sessionStorage.removeItem('hasRefreshed')
    // }
  });
export default router
