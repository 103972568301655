import Vue from 'vue'
import App from './App.vue'
import './styles/common.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import './assets/font/iconfont.css' // 下载的iconfont
import Vant from 'vant';
import 'vant/lib/index.css';
import store from './store/index'
// import { device } from '@/utils/public'
Vue.use(Vant);
Vue.config.productionTip = false
Vue.use(ElementUI);
// if (device().isPhone && (window.location.pathname.indexOf('mobile') === -1)) {
//   router.push('/mobile');
// } else {
//   // router.replace('/')
// }
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
